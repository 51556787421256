import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

//import abaclick from '../images/products/click.jpg'

const Page404 = () => (
    <Layout>
        <section id="intro">
            <h1>404</h1>
            <p>Gesuchte Seite wurde nicht gefunden</p>
            <Link to="/">Zurück zur Startseite</Link>
        </section>
    </Layout >
)

export default Page404
